'use client'
import { Box, Group, Popover, Select, Text, UnstyledButton } from '@mantine/core'
import React from 'react'
import classes from './VideoFilterPopover.module.css'
import { IconChevronDown } from '@tabler/icons-react'
import clsx from 'clsx'
import { useDisclosure } from '@mantine/hooks'
import { FormattedFilterData } from '../providers/AllVideosProvider'

interface VideoFilterPopover {
  data: FormattedFilterData
  value: string | null
  setValue: (slug: string | null) => void
  label: string
  fullWidth?: boolean
}

export const VideoFilterPopover: React.FC<VideoFilterPopover> = ({
  data,
  value,
  setValue,
  label,
  fullWidth = false,
}) => {
  const [popoverOpened, { toggle: togglePopover, close: closePopover }] = useDisclosure(false)

  return (
    <Popover
      opened={popoverOpened}
      onClose={closePopover}
      onChange={togglePopover}
      closeOnEscape={true}
      position="bottom-start"
      trapFocus
      width={fullWidth ? 'calc(100% - 2rem)' : undefined}
      classNames={{
        dropdown: classes.popoverDropdown,
      }}
    >
      <Popover.Target>
        <Group
          component={UnstyledButton}
          justify={fullWidth ? 'space-between' : 'flex-start'}
          gap="xs"
          wrap="nowrap"
          onClick={togglePopover}
          className={clsx(
            classes.button,
            {
              [classes.buttonActive]: popoverOpened,
              [classes.fullWidth]: fullWidth,
            },
            'mantine-active',
          )}
        >
          <Text className={classes.text} fw={600}>
            {value ? data.slugToLabelMap[value] : label}
          </Text>
          <Box className={classes.icon}>
            <Box
              component={IconChevronDown}
              className={clsx(classes.chevron, { [classes.chevronOpened]: popoverOpened })}
            />
          </Box>
        </Group>
      </Popover.Target>
      <Popover.Dropdown p={0}>
        <Select
          size="md"
          dropdownOpened={true}
          variant="unstyled"
          data={data.data}
          placeholder={label}
          value={value}
          onChange={(_value, option) => {
            setValue(option?.value || null)
            closePopover()
          }}
          searchable={true}
          clearable={true}
          classNames={{
            root: classes.selectRoot,
            option: classes.selectOption,
            input: classes.selectInput,
            dropdown: classes.selectDropdown,
          }}
          defaultDropdownOpened={true}
          nothingFoundMessage="Ничего не найдено"
          comboboxProps={{
            withinPortal: false,
            offset: 0,
          }}
          scrollAreaProps={{
            offsetScrollbars: false,
          }}
        />
      </Popover.Dropdown>
    </Popover>
  )
}
