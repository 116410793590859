'use client'
import React from 'react'
import { useAllVideosContext } from '../../providers/AllVideosProvider'
import { Box } from '@mantine/core'
import Image from 'next/image'
import classes from './SelectedVideo.module.css'

export const CoverImage = () => {
  const { selectedVideo } = useAllVideosContext()

  const coverImage =
    typeof selectedVideo?.coverImage === 'object' ? selectedVideo?.coverImage : null

  return (
    selectedVideo &&
    coverImage &&
    coverImage?.url && (
      <Box className={classes.imageContainer}>
        <Image
          src={coverImage?.url}
          alt={coverImage?.alt || selectedVideo.title}
          fill={true}
          draggable={false}
          style={{ objectFit: 'cover', objectPosition: 'center' }}
        />
        <Box className={classes.imageOverlay} />
      </Box>
    )
  )
}
