import(/* webpackMode: "eager", webpackExports: ["Box"] */ "/app/node_modules/.pnpm/@mantine+core@7.13.2_@mantine+hooks@7.13.2_react-dom@19.0.0-rc-2d16326d-20240930_react@19.0.0_dgbte4nawart77kikpwnq7qcxe/node_modules/@mantine/core/esm/core/Box/Box.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["LexicalEditorTheme"] */ "/app/node_modules/.pnpm/@payloadcms+richtext-lexical@3.0.0-beta.111_@faceless-ui+modal@3.0.0-beta.2_@faceless-ui+scro_j4gudttqoncmnhavfbobwrlyse/node_modules/@payloadcms/richtext-lexical/dist/lexical/theme/EditorTheme.js");
;
import(/* webpackMode: "eager" */ "/app/src/modules/layout/templates/page/page-template.module.css");
;
import(/* webpackMode: "eager", webpackExports: ["SelectedVideo"] */ "/app/src/modules/video/components/selected-video/SelectedVideo.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["VideoCarousel"] */ "/app/src/modules/video/components/VideoCarousel.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["VideoFilters"] */ "/app/src/modules/video/components/VideoFilters.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["AllVideosProvider"] */ "/app/src/modules/video/providers/AllVideosProvider.tsx");
