import { Box, BoxProps, SimpleGrid } from '@mantine/core'
import React from 'react'
import { VideoCardPlaceholder } from './VideoCardPlaceholder'
import classes from './VideoCarouselPlaceholder.module.css'
import clsx from 'clsx'

export const VideoCarouselPlaceholder: React.FC<BoxProps> = ({ className, ...rest }) => {
  return (
    <Box className={clsx(classes.root, className)} {...rest}>
      <SimpleGrid cols={{ base: 2, xs: 3, md: 4, lg: 5 }} spacing={{ base: 'sm', md: 'md' }}>
        <VideoCardPlaceholder />
        <VideoCardPlaceholder />
        <VideoCardPlaceholder visibleFrom="xs" />
        <VideoCardPlaceholder visibleFrom="md" />
        <VideoCardPlaceholder visibleFrom="lg" />
      </SimpleGrid>
    </Box>
  )
}
