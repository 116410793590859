'use client'
import {
  ButtonProps,
  Menu,
  MenuDropdown,
  MenuItem,
  MenuLabel,
  MenuTarget,
  Text,
} from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { RefObject } from 'react'
import { GiftButtonBase } from './GiftButtonBase'
import { isAuthorized } from '@/modules/common/actions'
import { useRouter } from 'next/navigation'
import { Video } from '@/payload-types'
import { testPaymentGift } from '@/modules/video/actions'

interface GiftButtonProps extends ButtonProps {
  children?: React.ReactNode
  video: Video
  ref?: RefObject<HTMLButtonElement>
}

export const GiftButton: React.FC<GiftButtonProps> = ({ children, video, ref, size = 'md' }) => {
  const [opened, { toggle, open, close }] = useDisclosure(false)
  const router = useRouter()

  const handleClick = async () => {
    console.log('Handling click')
    if (opened) {
      close()
      return
    }
    const { authorized } = await isAuthorized()

    if (!authorized) {
      router.push('/login')
    } else {
      open()
    }
  }

  return (
    <>
      <Menu opened={opened} onClose={close} position="bottom-start" width={272}>
        <MenuTarget>
          <GiftButtonBase ref={ref} size={size} onClick={handleClick} />
        </MenuTarget>
        <MenuDropdown px="sm" py="xs" bg="dark.7">
          <MenuLabel c="dark.0" fw={700} fz="sm" mb="xs">
            Выберите способ оплаты
          </MenuLabel>
          <MenuItem
            px="md"
            py="xs"
            bg="dark.5"
            fw={600}
            rightSection={
              <Text fw={600} size="sm">
                {video?.price?.rub!} ₽
              </Text>
            }
            onClick={() => testPaymentGift(video.id)}
          >
            Тестовая оплата
          </MenuItem>
        </MenuDropdown>
      </Menu>
    </>
  )
}
