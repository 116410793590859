import React, { useCallback, useMemo } from 'react'
import { Group, Text, UnstyledButton } from '@mantine/core'
import { Icon } from '@/modules/common/components/Icon'
import { IconCheck } from '@tabler/icons-react'
import classes from './VideoFilterSelector.module.css'
import clsx from 'clsx'

interface VideoFilterSelectorProps {
  data: {
    value: string
    label: string
  }
  setValue: (slug: string | null) => void
  currentValue: string | null
}

export const VideoFilterSelector: React.FC<VideoFilterSelectorProps> = ({
  data,
  setValue,
  currentValue,
}) => {
  const active = useMemo(() => {
    return data.value === currentValue
  }, [currentValue, data.value])

  const handleClick = useCallback(() => {
    if (currentValue === data.value) {
      setValue(null)
    } else {
      setValue(data.value)
    }
  }, [currentValue, data.value, setValue])

  return (
    <UnstyledButton
      px="sm"
      py="xs"
      className={clsx(classes.root, {
        [classes.active]: active,
      })}
      onClick={handleClick}
    >
      <Group justify="space-between">
        <Text size="sm" fw={500} lh={1} c={active ? 'white' : 'dimmed'}>
          {data.label}
        </Text>
        {currentValue === data.value && <Icon icon={IconCheck} size="sm" />}
      </Group>
    </UnstyledButton>
  )
}
