import { AspectRatio, Box, BoxProps, Card } from '@mantine/core'
import React from 'react'
import classes from './VideoCarouselPlaceholder.module.css'
import clsx from 'clsx'

export const VideoCardPlaceholder: React.FC<BoxProps> = ({ className, ...rest }) => {
  return (
    <Box className={clsx(classes.root, className)} {...rest}>
      <AspectRatio ratio={3 / 4} className={classes.aspectRatio}>
        <Card radius="md"></Card>
      </AspectRatio>
    </Box>
  )
}
