'use client'
import React from 'react'
import {
  Accordion,
  AccordionControl,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Drawer,
  Group,
  Stack,
  Text,
  UnstyledButton,
} from '@mantine/core'
import { VideoFilterPopover } from './VideoFilterPopover'
import classes from './VideoFilters.module.css'
import { IconChevronRight, IconFilterCheck } from '@tabler/icons-react'
import clsx from 'clsx'
import { useDisclosure } from '@mantine/hooks'
import { useAllVideosContext } from '../providers/AllVideosProvider'
import { Container } from '@/modules/common/components/Container'
import { VideoFilterSelector } from './VideoFilterSelector'
import { Icon } from '@/modules/common/components/Icon'

export const VideoFilters: React.FC = () => {
  const {
    cities,
    cityFilter,
    setCityFilter,
    theatres,
    theatreFilter,
    setTheatreFilter,
    plays,
    playFilter,
    setPlayFilter,
    genres,
    genreFilter,
    setGenreFilter,
  } = useAllVideosContext()
  const [opened, { toggle, open, close }] = useDisclosure(false)

  return (
    <Container>
      <Group py={{ base: 'sm', md: 'md' }} visibleFrom="xs">
        <VideoFilterPopover
          data={cities}
          value={cityFilter}
          setValue={setCityFilter}
          label="Город"
        />
        <VideoFilterPopover
          data={theatres}
          value={theatreFilter}
          setValue={setTheatreFilter}
          label="Театр"
        />
        <VideoFilterPopover
          data={plays}
          value={playFilter}
          setValue={setPlayFilter}
          label="Спектакль"
        />
        <VideoFilterPopover
          data={genres}
          value={genreFilter}
          setValue={setGenreFilter}
          label="Жанр"
        />
      </Group>
      <Group
        component={UnstyledButton}
        gap="md"
        justify="space-between"
        wrap="nowrap"
        className={clsx(classes.button, 'mantine-active')}
        hiddenFrom="xs"
        my="sm"
        onClick={open}
      >
        <Text className={classes.text} fw={700}>
          Фильтры
        </Text>
        <Box className={classes.icon}>
          <Box
            component={IconChevronRight}
            className={clsx(classes.chevron, { [classes.chevronOpened]: opened })}
            strokeWidth={3}
          />
        </Box>
      </Group>
      <Drawer
        opened={opened}
        onClose={close}
        position="bottom"
        closeOnClickOutside={true}
        withCloseButton={false}
        zIndex={10}
        withinPortal={true}
        size="sm"
        styles={{
          content: {
            borderTopRightRadius: 'var(--mantine-radius-lg)',
            borderTopLeftRadius: 'var(--mantine-radius-lg)',
          },
        }}
      >
        <Accordion>
          <AccordionItem value="city">
            <AccordionControl>
              <Group gap={'xs'}>
                {cityFilter && <Icon icon={IconFilterCheck} size="1rem" strokeWidth={2} />}
                <Text fw={500}>Город</Text>
              </Group>
            </AccordionControl>
            <AccordionPanel>
              <Stack gap={1}>
                {cities.data.map((item) => (
                  <VideoFilterSelector
                    key={item.value}
                    data={item}
                    currentValue={cityFilter}
                    setValue={setCityFilter}
                  />
                ))}
              </Stack>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="theatre">
            <AccordionControl>
              <Group gap={'xs'}>
                {theatreFilter && <Icon icon={IconFilterCheck} size="1rem" strokeWidth={2} />}
                <Text fw={500}>Театр</Text>
              </Group>
            </AccordionControl>
            <AccordionPanel>
              <Stack gap={1}>
                {theatres.data.map((item) => (
                  <VideoFilterSelector
                    key={item.value}
                    data={item}
                    currentValue={theatreFilter}
                    setValue={setTheatreFilter}
                  />
                ))}
              </Stack>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="play">
            <AccordionControl>
              <Group gap={'xs'}>
                {playFilter && <Icon icon={IconFilterCheck} size="1rem" strokeWidth={2} />}
                <Text fw={500}>Спектакль</Text>
              </Group>
            </AccordionControl>
            <AccordionPanel>
              <Stack gap={1}>
                {plays.data.map((item) => (
                  <VideoFilterSelector
                    key={item.value}
                    data={item}
                    currentValue={playFilter}
                    setValue={setPlayFilter}
                  />
                ))}
              </Stack>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="genre">
            <AccordionControl>
              <Group gap={'xs'}>
                {genreFilter && <Icon icon={IconFilterCheck} size="1rem" strokeWidth={2} />}
                <Text fw={500}>Жанр</Text>
              </Group>
            </AccordionControl>
            <AccordionPanel>
              <Stack gap={1}>
                {genres.data.map((item) => (
                  <VideoFilterSelector
                    key={item.value}
                    data={item}
                    currentValue={genreFilter}
                    setValue={setGenreFilter}
                  />
                ))}
              </Stack>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </Drawer>
    </Container>
  )
}
