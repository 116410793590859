export const COLLECTION_SLUG = {
  CITY: 'city',
  CUSTOMER: 'customer',
  CUSTOMER_LIBRARY: 'customer-library',
  CUSTOMER_RENTAL: 'customer-rental',
  GENRE: 'genre',
  MEDIA: 'media',
  ORDER: 'order',
  PAGE: 'page',
  PEOPLE: 'people',
  PLAY: 'play',
  SESSION: 'session',
  THEATRE: 'theatre',
  UPLOAD: 'upload',
  USER: 'user',
  VIDEO: 'video',
} as const

export const GLOBAL_SLUG = {
  SETTINGS: 'settings',
} as const

export const BLOCK_SLUG = {
  ACCORDION: 'accordion',
  FORM: 'form',
} as const

export const AUTH_REDIRECT_COOKIE = 'auth_redirect'
export const AUTH_REDIRECT_QUERY_PARAM = 'redirect'

//Not implemented
export const reservedRoutes = ['/', '/account']
