'use client'
import { Container } from '@/modules/common/components/Container'
import { Carousel, CarouselSlide } from '@mantine/carousel'
import React from 'react'
import { VideoCard } from './VideoCard'
import { useAllVideosContext } from '../providers/AllVideosProvider'
import { VideoCarouselPlaceholder } from './VideoCarouselPlaceholder'

interface VideoCarouselProps {}

export const VideoCarousel: React.FC<VideoCarouselProps> = ({}) => {
  const { filteredVideos: videos, selectedVideo, setSelectedVideo } = useAllVideosContext()

  return (
    <Container>
      {videos.length > 0 ? (
        <Carousel
          withControls={false}
          slideSize={{ base: '50%', xs: '33.333%', md: '25%', lg: '20%' }}
          slideGap={{ base: 'sm', md: 'md' }}
          align="start"
          containScroll="trimSnaps"
          draggable={false}
          styles={{
            viewport: {
              overflow: 'visible',
            },
          }}
        >
          {videos.map((video) => (
            <CarouselSlide key={video.id}>
              <VideoCard
                video={video}
                onClick={() => setSelectedVideo(video)}
                selected={selectedVideo?.id === video.id}
              />
            </CarouselSlide>
          ))}
        </Carousel>
      ) : (
        <VideoCarouselPlaceholder />
      )}
    </Container>
  )
}
