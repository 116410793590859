'use client'
import {
  Anchor,
  Box,
  Center,
  Drawer,
  Group,
  Modal,
  ScrollArea,
  Stack,
  Text,
  Title,
} from '@mantine/core'
import React from 'react'
import { useAllVideosContext } from '../../providers/AllVideosProvider'
import classes from './SelectedVideo.module.css'
import { Container } from '@/modules/common/components/Container'
import { useDisclosure } from '@mantine/hooks'
import { VideoInfo } from './VideoInfo'

export const VideoInfoPreview = ({ children }: { children: React.ReactNode }) => {
  const { selectedVideo } = useAllVideosContext()

  const [infoOpened, { toggle: toggleInfo, close: closeInfo }] = useDisclosure(false)
  return (
    <Box className={classes.content}>
      {selectedVideo ? (
        <Container>
          <Stack className={classes.contentWrapper}>
            <Title className={classes.title}>{selectedVideo.title}</Title>
            <Text lineClamp={3}>{selectedVideo.plot}</Text>
            <Anchor
              component="button"
              fw={700}
              mr="auto"
              onClick={toggleInfo}
              className={'mantine-active'}
            >
              Подробнее
            </Anchor>
            <Group>{children}</Group>
          </Stack>
          <Modal
            title={
              <Text fw={700} c="white" size="lg">
                {selectedVideo?.title}
              </Text>
            }
            opened={infoOpened}
            onClose={closeInfo}
            size="xl"
            yOffset={`var(--header-height)`}
            padding="xl"
            radius="md"
            visibleFrom="xs"
            scrollAreaComponent={ScrollArea.Autosize}
            zIndex={300}
          >
            <VideoInfo video={selectedVideo}>{children}</VideoInfo>
          </Modal>
          <Drawer
            title={
              <Text fw={700} c="white" size="lg">
                {selectedVideo?.title}
              </Text>
            }
            opened={infoOpened}
            onClose={closeInfo}
            size="xl"
            hiddenFrom="xs"
            zIndex={300}
          >
            <VideoInfo video={selectedVideo}>{children}</VideoInfo>
          </Drawer>
        </Container>
      ) : (
        <Container>
          <Center>
            <Title ff="text" fw={500} fz="h2">
              Спектаклей не найдено
            </Title>
          </Center>
        </Container>
      )}
    </Box>
  )
}
