'use client'
import React, {
  Dispatch,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { AllVideosProps } from '../templates/AllVideos'
import { COLLECTION_SLUG } from '@/constants'
import { parseAsString, useQueryState } from 'nuqs'
import { Video } from '@/payload-types'

export interface FormattedFilterData {
  data: { value: string; label: string }[]
  slugToLabelMap: Record<string, string>
}

interface AllVideosContextProps {
  videos: Video[]
  filteredVideos: Video[]
  selectedVideo: Video | null
  setSelectedVideo: Dispatch<SetStateAction<Video>>
  cityFilter: string | null
  setCityFilter: (slug: string | null) => void
  theatreFilter: string | null
  setTheatreFilter: (slug: string | null) => void
  playFilter: string | null
  setPlayFilter: (slug: string | null) => void
  genreFilter: string | null
  setGenreFilter: (slug: string | null) => void
  cities: FormattedFilterData
  theatres: FormattedFilterData
  plays: FormattedFilterData
  genres: FormattedFilterData
}

const AllVideosContext = createContext<AllVideosContextProps | undefined>(undefined)

export const useAllVideosContext = () => {
  const context = useContext(AllVideosContext)

  if (context === undefined) {
    throw new Error(`useVideoContext must be used within a VideoProvider.`)
  }

  return context
}

interface AllVideosProviderProps {
  data: AllVideosProps['data']
  filtersData: AllVideosProps['filtersData']
  filters: AllVideosProps['filters']
  children?: React.ReactNode
}

export const AllVideosProvider: React.FC<AllVideosProviderProps> = ({
  children,
  data,
  filtersData,
}) => {
  const [selectedVideo, setSelectedVideo] = useState(data[0] ?? null)
  const [cityFilter, setCityFilter] = useQueryState(
    COLLECTION_SLUG.CITY,
    parseAsString.withOptions({ clearOnDefault: true }).withDefault(''),
  )
  const [theatreFilter, setTheatreFilter] = useQueryState(
    COLLECTION_SLUG.THEATRE,
    parseAsString.withOptions({ clearOnDefault: true }).withDefault(''),
  )
  const [playFilter, setPlayFilter] = useQueryState(
    COLLECTION_SLUG.PLAY,
    parseAsString.withOptions({ clearOnDefault: true }).withDefault(''),
  )
  const [genreFilter, setGenreFilter] = useQueryState(
    COLLECTION_SLUG.GENRE,
    parseAsString.withOptions({ clearOnDefault: true }).withDefault(''),
  )

  const videos = useMemo(() => {
    return data.filter((video) => {
      const cityCondition = cityFilter
        ? typeof video.theatre === 'object' &&
          video.theatre &&
          'city' in video.theatre &&
          video.theatre.city !== null &&
          typeof video.theatre.city === 'object' &&
          video.theatre.city.slug === cityFilter
        : true

      const theatreCondition = theatreFilter
        ? typeof video.theatre === 'object' && video.theatre && video.theatre.slug === theatreFilter
        : true

      const playCondition = playFilter
        ? typeof video.play === 'object' && video.play && video.play.slug === playFilter
        : true

      const genreCondition = genreFilter
        ? Array.isArray(video.genres) &&
          video.genres.some(
            (genre) => genre && typeof genre === 'object' && genre.slug === genreFilter,
          )
        : true

      return cityCondition && theatreCondition && playCondition && genreCondition
    })
  }, [cityFilter, theatreFilter, playFilter, genreFilter, data])

  useEffect(() => {
    setSelectedVideo(videos[0] ?? null)
  }, [videos])

  const cities = useMemo(() => {
    const validCities = filtersData.cities.filter((item) => item.slug && item.name)

    return {
      data: filtersData.cities.map((item) => ({
        value: item.slug !== null && item.slug !== undefined ? item.slug : '',
        label: item.name,
      })),
      slugToLabelMap: validCities.reduce((acc, item) => {
        acc[item.slug as string] = item.name
        return acc
      }, {} as Record<string, string>),
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtersData.cities, cityFilter])

  const theatres = useMemo(() => {
    const validTheatres = filtersData.theatres.filter((item) => item.slug && item.name)

    const filteredTheatres = cityFilter
      ? validTheatres.filter(
          (theatre) => typeof theatre.city === 'object' && theatre.city?.slug === cityFilter,
        )
      : validTheatres

    return {
      data: filteredTheatres.map((item) => ({
        value: item.slug !== null && item.slug !== undefined ? item.slug : '',
        label: item.name,
      })),
      slugToLabelMap: validTheatres.reduce((acc, item) => {
        acc[item.slug as string] = item.name
        return acc
      }, {} as Record<string, string>),
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtersData.theatres, theatreFilter, cities])

  const plays = useMemo(() => {
    const validPlays = filtersData.plays.filter((item) => item.slug && item.name)

    return {
      data: validPlays.map((item) => ({
        value: item.slug !== null && item.slug !== undefined ? item.slug : '',
        label: item.name,
      })),
      slugToLabelMap: validPlays.reduce((acc, item) => {
        acc[item.slug as string] = item.name
        return acc
      }, {} as Record<string, string>),
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtersData.plays, playFilter])

  const genres = useMemo(() => {
    const validGenres = filtersData.genres.filter((item) => item.slug && item.name)

    return {
      data: validGenres.map((item) => ({
        value: item.slug !== null && item.slug !== undefined ? item.slug : '',
        label: item.name,
      })),
      slugToLabelMap: validGenres.reduce((acc, item) => {
        acc[item.slug as string] = item.name
        return acc
      }, {} as Record<string, string>),
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtersData.genres, genreFilter])

  useEffect(() => {
    if (cityFilter && !cities.data.some((city) => city.value === cityFilter)) {
      setCityFilter(null)
    }
  }, [cityFilter, cities.data, setCityFilter])

  useEffect(() => {
    if (theatreFilter && !theatres.data.some((theatre) => theatre.value === theatreFilter)) {
      setTheatreFilter(null)
    }
  }, [theatreFilter, theatres.data, setTheatreFilter])

  useEffect(() => {
    if (playFilter && !plays.data.some((play) => play.value === playFilter)) {
      setPlayFilter(null)
    }
  }, [playFilter, plays.data, setPlayFilter])

  useEffect(() => {
    if (genreFilter && !genres.data.some((genre) => genre.value === genreFilter)) {
      setGenreFilter(null)
    }
  }, [genreFilter, genres.data, setGenreFilter])

  return (
    <AllVideosContext.Provider
      value={{
        videos: data,
        filteredVideos: videos,
        selectedVideo,
        setSelectedVideo,
        cityFilter,
        setCityFilter,
        theatreFilter,
        setTheatreFilter,
        playFilter,
        setPlayFilter,
        genreFilter,
        setGenreFilter,
        cities,
        theatres,
        plays,
        genres,
      }}
    >
      {children}
    </AllVideosContext.Provider>
  )
}
