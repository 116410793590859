'use client'
import React from 'react'
import classes from './SelectedVideo.module.css'
import { Box, Stack } from '@mantine/core'
import { WatchButton } from '@/modules/watch/components/WatchButton'
import { GiftButton } from '@/modules/watch/components/GiftButton'
import { CoverImage } from './CoverImage'
import { VideoInfoPreview } from './VideoInfoPreview'
import { useAllVideosContext } from '../../providers/AllVideosProvider'

interface SelectedVideoProps {
  children?: React.ReactNode
  carousel?: React.ReactNode
}

export const SelectedVideo: React.FC<SelectedVideoProps> = ({ children, carousel }) => {
  const { selectedVideo } = useAllVideosContext()

  return (
    <Box className={classes.root}>
      <CoverImage />
      <Stack className={classes.stack} justify="space-around">
        <VideoInfoPreview>
          <WatchButton video={selectedVideo!} />
          <GiftButton video={selectedVideo!} />
        </VideoInfoPreview>
        <Box className={classes.children}>
          {children}
          {carousel}
        </Box>
      </Stack>
    </Box>
  )
}
