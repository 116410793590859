import {
  AspectRatio,
  Badge,
  Box,
  BoxProps,
  Group,
  Paper,
  Skeleton,
  Stack,
  Text,
  Title,
  UnstyledButton,
} from '@mantine/core'
import { Video } from '@/payload-types'
import React from 'react'
import classes from './VideoCard.module.css'
import clsx from 'clsx'
import Image from 'next/image'
import { IconCircleFilled, IconMapPin } from '@tabler/icons-react'
import { PriceBadge } from '@/modules/common/components/PriceBadge'
import { IconPlay } from '@/modules/common/icons/IconPlay'

interface Props extends BoxProps {
  video: Video
  selected?: boolean
  withPrice?: boolean
  withPlayIcon?: boolean
  onClick?: () => void
}

export const VideoCard: React.FC<Props> = ({
  video,
  withPrice = true,
  withPlayIcon = false,
  selected,
  className,
  onClick,
  ...rest
}) => {
  const createdAtDate = new Date(video.createdAt)
  const today = new Date()
  const dayDifference = (today.getTime() - createdAtDate.getTime()) / (24 * 60 * 60 * 1000)
  const isNew = dayDifference <= 30 //created within 30 days

  return (
    <AspectRatio ratio={3 / 4} w="100%" className={classes.aspectRatio}>
      <UnstyledButton
        className={clsx(
          'mantine-active',
          classes.button,
          { [classes.selected]: selected },
          className,
        )}
        onClick={onClick}
        {...rest}
      >
        {withPlayIcon && <IconPlay className={classes.iconPlay} />}

        <Paper pos="relative" h="100%" w="100%">
          {typeof video?.coverImagePortrait === 'object' && video?.coverImagePortrait?.url && (
            <Image
              src={video.coverImagePortrait?.url || ''}
              alt={video.coverImagePortrait?.alt || ''}
              fill={true}
              draggable={false}
              quality={80}
              style={{ objectFit: 'cover' }}
            />
          )}
          <Box className={classes.overlay} />
          <Box className={classes.content}>
            <Stack justify="space-between" h="100%">
              {withPrice ? (
                <Group justify="space-between">
                  {video.price?.rub && <PriceBadge rub={video?.price?.rub} />}
                  {isNew && (
                    <>
                      <Badge color="red" radius="md" size="md" hiddenFrom="sm">
                        Новое
                      </Badge>
                      <Badge color="red" radius="md" size="lg" visibleFrom="sm">
                        Новое
                      </Badge>
                    </>
                  )}
                </Group>
              ) : (
                <Box />
              )}

              <Stack gap={0}>
                <Title order={2} size="md">
                  {video?.title}
                </Title>
                <Group>
                  {video.genres?.map((genre) => {
                    return typeof genre === 'object' && <Text key={genre.id}>{genre?.name}</Text>
                  })}
                </Group>
                <Box>
                  <Group gap="xs">
                    <Text>{video?.runtime} мин</Text>
                    <IconCircleFilled size="5" color="gray" />
                    <Text>{video?.rating}</Text>
                  </Group>
                  {typeof video?.theatre === 'object' && (
                    <Box className={classes.locationWrapper}>
                      <Group gap={4} className={clsx(classes.location)}>
                        <IconMapPin size="1rem" strokeWidth={2.42} />
                        <Text c="white">{video?.theatre?.name}</Text>
                      </Group>
                    </Box>
                  )}
                </Box>
              </Stack>
            </Stack>
          </Box>
        </Paper>
      </UnstyledButton>
    </AspectRatio>
  )
}

export const VideoCardSkeleton = (props: BoxProps) => {
  return (
    <AspectRatio ratio={3 / 4} w="100%" className={classes.aspectRatio} {...props}>
      <Skeleton w="100%" h="100%" radius="md" />
    </AspectRatio>
  )
}
